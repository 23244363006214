import { io } from "socket.io-client";

//inicialitzacio del socket
class SocketioService {
  socket;
  constructor() {}

  //inicia connexio
  setupSocketConnection() {
    this.socket = io(process.env.VUE_APP_SOCKET_ENDPOINT, {
      //this.socket = io("http://localhost:3000", {
      transports: ["websocket"],
    });
  }
  // finalitza connexio
  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }
}

export default new SocketioService();
