<template>
  <img
    class="rounded mx-auto d-block"
    src="./assets/logo.png"
    alt="logo Share music"
  />
  <h1>Listen to music with friends at the same time!</h1>
  <!--   <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div> -->
  <router-view />

  <footer>
    <ul>
      <li>Alba Mateu</li>
      <li><a href="https://almape.dev/#contact" target="_blank">Contact</a></li>
    </ul>
  </footer>
</template>

<script>
import SocketioService from "./services/socketio.service.js";
import List from "./components/List.vue";

export default {
  name: "App",
  components: { List },
  created() {
    //inicialitza la connexio de socket
    SocketioService.setupSocketConnection();
  },
  beforeUnmount() {
    //desconecta el socket
    SocketioService.disconnect();
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: justify;
  color: #2c3e50;
  margin-top: 60px;
}

h1 {
  text-align: center;
}

footer {
  background-image: url("./assets/wave.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
  background-position-x: center; /* horitzontal */
  background-position-y: 47%; /* vertical */

  width: 100%;
  height: 280px;
  color: #fff;
  display: flex;
  align-items: end;
  justify-content: center;
}
footer ul {
  display: flex;
  padding: 1rem 0;
  margin: 0;
}
li {
  list-style: none;
  padding: 0 0.5rem;
  letter-spacing: 0.5px;
}

li a,
li a:hover {
  text-decoration: none;
  /*color: #1f1e1e;*/
  color: #fff;
}

li:first-child {
  /* border-right: #1f1e1e5c solid 1px; */
  border-right: #fff solid 1px;
}

/* mobils */
@media (max-width: 575.98px) {
  h1 {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  footer ul {
    left: 35%;
  }
}
</style>
