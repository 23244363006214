<template id="list-template">
  <div class="container">
    <div class="row">
      <div id="column-left" class="col-lg-5 col-11" v-if="columnLeft">
        <div id="title">
          <h2>My playlist</h2>
          <div id="share" class="btn_wrap">
            <span>Share</span>
            <div class="share_btn">
              <a id="telegram" @click.stop="telegramLink()"
                ><font-awesome-icon
                  :icon="['fab', 'telegram']"
                  color="black"
                  size="lg"
                />
              </a>
              <a id="whatsapp" @click.stop="whatsappLink()"
                ><font-awesome-icon
                  :icon="['fab', 'whatsapp']"
                  color="black"
                  size="lg"
                />
              </a>
              <a id="clipboard" @click.stop="copyLink()"
                ><font-awesome-icon
                  :icon="['fas', 'copy']"
                  color="black"
                  size="lg"
                />
              </a>
            </div>
          </div>
        </div>

        <div class="jumbotron">
          <div class="actual_song" v-if="actualSong != null">
            <div id="info-song">
              <p>{{ actualSong.title }}</p>
            </div>
            <div id="progressBar">
              <p>{{ time }}</p>
              <progress id="bar"></progress>
              <p>{{ duration }}</p>
            </div>
          </div>
          <div id="rep-list-buttons" class="text-center">
            <button class="btn" @click.stop="previousSong()">
              <font-awesome-icon :icon="['fas', 'step-backward']" />
            </button>
            <button class="btn" v-if="!play" @click="playPlaylist()">
              <font-awesome-icon :icon="['fas', 'play']" />
            </button>
            <button class="btn" v-if="play" @click="enviarPausePlaylist()">
              <font-awesome-icon :icon="['fas', 'pause']" />
            </button>
            <button class="btn" @click="nextSong()">
              <font-awesome-icon :icon="['fas', 'step-forward']" />
            </button>
            <button
              class="btn"
              v-bind:class="{ color: loop }"
              @click="loopOnOff()"
            >
              <font-awesome-icon :icon="['fas', 'undo-alt']" />
            </button>
            <button
              class="btn"
              v-bind:class="{ color: random }"
              @click="randomOnOff()"
            >
              <font-awesome-icon :icon="['fas', 'random']" />
            </button>
          </div>
        </div>

        <div id="playlist" class="list-group row">
          <div
            class="list-group-item list-group-item-action"
            v-for="(song, i) in playList"
            :key="'song' + i"
            :id="song.id"
          >
            <div class="col-md-9 col-sm-7 col-12">{{ song.title }}</div>
            <div id="buttons" class="col-md-3 col-sm-5 col-12">
              <button
                id="play"
                class="btn"
                @click.stop="playPlaylist(song.idVideo)"
              >
                <font-awesome-icon :icon="['fas', 'play-circle']" />
              </button>
              <button id="remove" class="btn" @click="removeList(song.id)">
                <font-awesome-icon :icon="['fas', 'minus-circle']" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        id="column-right"
        class="col-11"
        :class="{ 'ms-auto col-lg-5': columnLeft }"
      >
        <h2>Search</h2>
        <!-- .prevent evita que la pagina es recarge al fer el submit -->
        <form
          @submit.prevent="searchSong"
          class="form-inline mb-3 offset-sm-2 col-sm-8 col-12"
        >
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              v-model="newSong"
              id="song"
              placeholder="Searches a song"
              aria-label="Searches a song"
              aria-describedby="search"
              autocomplete="off"
            />
            <button class="btn btn-outline-secondary" type="submit" id="search">
              <font-awesome-icon :icon="['fas', 'search']" />
            </button>
          </div>
        </form>
        <div id="list" class="list-group row">
          <div
            class="list-group-item list-group-item-action"
            v-for="(video, i) in videos"
            :key="'video' + i"
          >
            <div class="col-md-8 col-sm-7 col-12">
              {{ video.snippet.title }}
            </div>
            <div id="rep-son-buttons" class="col-md-4 col-sm-5 col-12">
              <button
                id="play"
                class="btn"
                @click.stop="playSong(video.id.videoId)"
              >
                <font-awesome-icon :icon="['fas', 'play-circle']" />
              </button>
              <button id="stop" class="btn" @click="stopSong()">
                <font-awesome-icon :icon="['fas', 'stop-circle']" />
              </button>
              <button
                id="add"
                class="btn"
                @click="addList(video.id.videoId, video.snippet.title)"
              >
                <font-awesome-icon :icon="['fas', 'plus-circle']" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="video"></div>
  <div id="PL"></div>
</template>

<script>
import axios from "axios";
import socketioService from "../services/socketio.service.js";
import config from "../config.vue";
var player;
var playerVideo;
var api_key = config.API_KEY;

export default {
  name: "List",
  data: () => ({
    newSong: "",
    actualSong: null,
    time: null,
    timeCurrentSeg: null, //temps en reproduccio en segons
    duration: null, //minuts
    durationSegs: null, //segons
    interval: null,
    videos: [], //on es guarden les cançons del search
    playList: [], //es guarden les de la playlist
    loop: false,
    random: false,
    play: false,
    columnLeft: false,
    repPos: 0,
    repCurrentTime: null, //temps de reproduccio
    historyReprod: [], //historial de reproduccions aleatories per all previous
    reproducidos: [], //reproduides per a no repetir en random
    socket: socketioService.socket,
    room: null,
    linkRoom: null,
    start: false,
  }) /* fin data */,

  methods: {
    searchSong() {
      axios
        .get(
          "https://www.googleapis.com/youtube/v3/search?part=snippet&maxResults=10&q=" +
            this.newSong +
            "&type=video&key=" +
            api_key
        )
        .then((result) => {
          this.videos = result.data.items;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    myRoom(event) {
      //agarrar room de la url si existeix parametros i no existeix room
      if (this.$route.params.room) {
        let params = window.atob(this.$route.params.room);
        if (params !== this.room) {
          //crea link compartir
          this.socket.emit("room_set", params);
        }
      }
    },
    roomStart() {
      //posar vista a punt
      if (this.columnLeft === true) {
        this.socket.emit("room_start_set", {
          column: true,
          loop: this.loop,
          random: this.random,
          playlist: this.playList,
        });
      }
    },

    rebreDadesServer() {
      //rebre room del servidor
      this.socket.on("room_get", (data) => {
        if (data) {
          this.room = data;
          this.linkRoom = window.location.origin + "/" + window.btoa(this.room);
        }
      });
      //rebre peticio enviar dades de la cançó al nou en la room
      this.socket.on("peticio_reproduccio", (data) => {
        this.roomStart();
      });
      //posar en marxa la room copia datos de la room
      this.socket.on("room_start_get", (data) => {
        if (this.$route.params.room && player.getPlayerState() !== 1) {
          this.start = true;
          this.columnLeft = data.column;
          this.playList = data.playlist;
          this.random = data.random;
          this.loop = data.loop;
        }
      });
      //rebre info de la canço reproduida actual new room
      this.socket.on("send_you_info", (data) => {
        if (!this.start) {
          this.socket.emit("position_set_start", {
            duration: this.duration,
            durationSegs: this.durationSegs,
            actual: this.actualSong,
            pos: this.repPos,
            time: player.getCurrentTime(),
          });
        }
      });

      //rebre actualSong
      this.socket.on("actualSong_get", (data) => {
        this.actualSong = data;
      });
      //rebre posicio actual
      this.socket.on("position_get_start", (data) => {
        if (this.start && !this.play && player.getPlayerState() !== 1) {
          this.repPos = data.pos;
          this.repCurrentTime = data.time;
          this.actualSong = data.actual;
          this.duration = data.duration;
          this.durationSegs = data.durationSegs;
          this.loadVideo(data.pos, data.time);
        }
        this.start = false;
      });
      //rebre posicio actual
      this.socket.on("position_get", (data) => {
        this.repPos = data.pos;
        this.loadVideo(data.pos, data.time);
      });
      //rebre canço a insertar i insertar en play playlist
      this.socket.on("addList_get", (data) => {
        this.columnLeft = data.column;
        this.addSong(data.id, data.title);
      });
      //rebre posicio a eliminar del array playlist
      this.socket.on("removeList_get", (data) => {
        this.playList.splice(data.pos, 1);
      });
      //rebre estat de random
      this.socket.on("random_get", (data) => {
        this.random = data;
      });
      //rebre estat de loop
      this.socket.on("loop_get", (data) => {
        this.loop = data;
      });
      //rebre estat de play
      this.socket.on("playBolean_get", (data) => {
        this.play = data;
      });
      //executar pause
      this.socket.on("pause_get", (data) => {
        this.pauseSong();
      });
      //executar stop
      this.socket.on("stop_get", (data) => {
        this.stopPlaylist();
      });
      //rebre array reproducidos
      this.socket.on("reproducidos_get", (data) => {
        this.reproducidos = data;
      });
      //rebre array historic reproducidos
      this.socket.on("historyReprod_get", (data) => {
        this.historyReprod = data;
      });
    },
    enviarIdVideo(id, time) {
      // NO SE USA
      socketioService.setIdVideo(id, time);
    },
    enviarStopPlaylist() {
      this.socket.emit("stop_set", true);
    },
    enviarPausePlaylist() {
      this.socket.emit("pause_set", true);
    },
    setBoleanPlay(play) {
      this.socket.emit("playBolean_set", play);
    },
    playSong(idVideo) {
      //per a canço individual
      let s = this.findVideoById(idVideo);
      if (s) {
        s.play = !s.play;
      }
      /* crea l'objecte youtube i el iframe */
      playerVideo = new YT.Player("video", {
        width: 50,
        height: 50,
        videoId: idVideo,
        playerVars: { autoplay: 1, controls: 0 },
        events: {
          onReady: this.onPlayerReady,
        },
      });
      document.getElementById("video").style.visibility = "hidden";
      //para la canço als 10 segons
      setTimeout(this.stopSong, 10000);
    },
    stopSong() {
      //per a canço individual
      playerVideo.stopVideo();
      /* destruix el iframe */
      playerVideo.destroy();
    },
    onPlayerReady(event) {
      //per a canço individual
      event.target.playVideo();
    },
    randomOnOff() {
      //per a playlist
      this.random = !this.random;
      this.socket.emit("random_set", this.random);
    },
    randomOrder() {
      let random = Math.floor(Math.random() * this.playList.length);
      if (this.loop && this.playList.length == this.reproducidos.length) {
        //si esta actiu loop i ja s'han reproduit totes
        this.historyReprod = this.historyReprod.concat(this.reproducidos);
        this.socket.emit("historyReprod_set", this.historyReprod);
        //buida el array reproducidos per a tornar a reproduirles totes
        this.reproducidos = [];
      }

      //mentre random existixca en el array
      while (this.reproducidos.includes(random)) {
        random = Math.floor(Math.random() * this.playList.length);
      }
      this.reproducidos.push(random);
      this.socket.emit("reproducidos_set", this.reproducidos);
      return random;
    },
    loopOnOff() {
      //per a playlist
      this.loop = !this.loop;
      this.socket.emit("loop_set", this.loop);
    },
    addList(id, title) {
      if (!this.columnLeft) {
        this.columnLeft = true;
      }
      //this.addSong(id, title);
      //envia al server la canço a insertar
      this.socket.emit("addList_set", {
        column: this.columnLeft,
        id: id,
        title: title,
      });
    },
    addSong(id, title) {
      let pos = this.playList.length;
      let song = {
        id: pos,
        idVideo: id,
        title: title,
      };
      this.playList.push(song);
    },
    removeSong(pos) {
      return new Promise((resolve) => {
        this.playList.forEach((song, i) => {
          if (song.id == pos) {
            //envia posicio a eliminar del array playlist
            this.socket.emit("removeList_set", {
              pos: i,
            });
            /* this.playList.splice(i, 1); */
          }
        });
      });
    },
    removeList(pos) {
      //elimina un element de la llista
      this.removeSong(pos).then(function () {
        /* eliminar div HTML */
        document.getElementById(pos).remove();
      });
      //amaga la columna esquerra de la vista quan no hi ha elements en la llista
      if (this.playList.length < 1) {
        this.columnLeft = false;
      }
    },
    stopPlaylist() {
      player.stopVideo();
      clearInterval(this.interval);
      this.interval = null;
    },
    nextSong() {
      //per a playlist
      if (this.random) {
        //aleatori
        if (!this.loop && this.playList.length == this.reproducidos.length) {
          //si no es repetix tot, para quan reproducidos siga igual que playlist
          //voldrá dir que s'han reproduit totes
          this.play = false;
          this.setBoleanPlay(false);
          this.reproducidos = [];
          this.enviarStopPlaylist();
        } else {
          this.repPos = this.randomOrder();
          //si no es repetixen o encara no s'han reproduit totes, play la següent
          this.updateSongActual(this.repPos);
        }
      } else {
        //no aleatori
        if (this.loop && this.repPos == this.playList.length - 1) {
          //si loop esta activat i está en la ultima cançó, la següent es la 0
          this.repPos = 0;
          this.updateSongActual(this.repPos);
        } else if (!this.loop && this.repPos == this.playList.length - 1) {
          //si loop NO esta activat i está en la ultima cançó, parar
          this.play = false;
          this.setBoleanPlay(false);
          this.repPos = 0;
          this.enviarStopPlaylist();
        } else {
          // si loop esta activat o no pero no esta en la ultima cançó, suma 1
          if (this.repPos < this.playList.length - 1) {
            //este if es per a evitar que repPos se'n passe de la llargaria de playlist
            this.repPos++;
            this.updateSongActual(this.repPos);
          }
        }
      }
    },
    previousSong() {
      //per a playlist
      if (this.random) {
        if (this.reproducidos.length > 0) {
          //  -1 es la actual per a que torne a començar la mateixa i si torna a apretar, la anterior
          this.repPos = this.reproducidos[this.reproducidos.length - 1];
          //elimina la posició actual de reproducidos perque sino no canviará a l'anterior
          this.reproducidos.pop();
        } else {
          //quan ja ha reproduit totes i torna a començar perque esta loop activat
          if (this.loop && this.historyReprod.length > 0) {
            this.repPos = this.historyReprod[this.historyReprod.length - 1];
            //elimina la posició actual de reproducidos perque sino no canviará a l'anterior
            this.historyReprod.pop();
          }
        }
      } else {
        // no es random
        if (this.loop && this.repPos == 0) {
          //si loop actiu i la posició actual es 0, la anterior es la última del array
          this.repPos = this.playList.length - 1;
        } else {
          if (this.repPos > 0) {
            //si la posicio actual es superior a 0, la anterior es -1
            this.repPos--;
          }
        }
      }
      this.updateSongActual(this.repPos);
    },

    pauseSong() {
      //per a playlist
      this.play = false;
      this.setBoleanPlay(false);
      this.repCurrentTime = player.getCurrentTime();
      player.pauseVideo();
    },
    playPlaylist(idVideo = null) {
      //per a playlist
      // play des del boto de la canço
      if (idVideo) {
        let video = this.findVideoById(idVideo);
        this.repPos = video.id;
        //per a que si esta en pause comence de 0 si posem play a una canço concreta
        this.updateSongActual(this.repPos);
      } else if (this.start) {
        //per a quan posa play el que entra en la room nou li passe els datos de play
        this.socket.emit("send_me_info", true);
      } else {
        //pause
        if (player.getPlayerState() === 2) {
          this.updateSongActual(this.repPos, this.repCurrentTime);
        } else {
          if (this.random) {
            this.repPos = this.randomOrder();
          }
          this.updateSongActual(this.repPos);
        }
      }
    },
    updateSongActual(pos, time = 0) {
      if (pos < this.playList.length && pos >= 0) {
        this.actualSong = this.playList[this.repPos];

        this.socket.emit("actualSong_set", this.actualSong);

        this.socket.emit("position_set", {
          pos: this.repPos,
          time: time,
        });
      }
    },
    loadVideo(pos, time = 0) {
      //per a playlist
      this.play = true;
      if (pos < this.playList.length && pos >= 0) {
        player.loadVideoById(
          this.playList[pos].idVideo,
          time,
          player.getDuration()
        );
        this.actualSong = this.playList[this.repPos];
      }
    },

    findVideoById(id) {
      let result = null;
      this.playList.forEach((song) => {
        if (song.idVideo == id) {
          result = song;
        }
      });
      return result;
    },
    timePlaying() {
      if (player != undefined) {
        if (
          this.timeCurrentSeg !== null &&
          this.timeCurrentSeg == this.durationSegs
        ) {
          //la canço no esta en play
          clearInterval(this.interval);
          this.interval = null;
          this.time = null;
        } else {
          //va canviant el temps que porta de reproduccio la canço
          this.interval = setInterval(() => {
            this.timeCurrentSeg = player.getCurrentTime();
            let min = Math.floor(this.timeCurrentSeg / 60);
            let seg = Math.floor(this.timeCurrentSeg % 60);
            if (seg < 10) {
              seg = "0" + seg;
            }
            this.time = min + ":" + seg;
            this.progressBarTime();
          }, 1000);
        }
      }
    },
    progressBarTime() {
      //posa temps actual reproduït al progresbar
      let actual = document.getElementById("bar");
      if (actual) {
        actual.setAttribute("value", this.timeCurrentSeg);
      }
    },
    onPlayerStateChange(event) {
      if (player != undefined) {
        let estat = event.target.getPlayerState();

        //1 es playing
        if (estat === 1) {
          if (this.interval == null) {
            this.timePlaying();
          }
          //temps duracio total de la cançó
          /* this.timeCurrentSeg = player.getCurrentTime(); */
          this.durationSegs = player.getDuration();
          let min = Math.floor(this.durationSegs / 60);
          let seg = Math.floor(this.durationSegs % 60);
          if (seg < 10) {
            seg = "0" + seg;
          }
          this.duration = min + ":" + seg;
          let actual = document.getElementById("bar");
          if (actual) {
            actual.setAttribute("max", this.durationSegs);
          }

          //2 es pause
        } else if (estat === 2) {
          clearInterval(this.interval);
          this.interval = null;
        }
        if (this.repPos < this.playList.length && this.repPos >= 0) {
          // 0 es ended
          if (estat === 0) {
            this.nextSong();
          }
        } else {
          this.enviarStopPlaylist();
        }
      }
    },
    onPlayerError(event) {
      console.log("error player playlist: ");
      console.log(event);
    },
    createdPlayer() {
      if (!player) {
        player = new YT.Player("PL", {
          height: "50",
          width: "50",
          videoId: null,
          playerVars: { autoplay: 1, controls: 0 },
          events: {
            onReady: this.myRoom,
            onStateChange: this.onPlayerStateChange,
            onError: this.onPlayerError,
          },
        });
        document.getElementById("PL").style.visibility = "hidden";
      }
    },
    copyLink() {
      navigator.clipboard
        .writeText(this.linkRoom)
        .then(() => {
          this.showAviso("Copied to clipboard");
        })
        .catch((err) => {
          this.showAviso("Could not copy to clipboard");
        });
    },
    showAviso(text) {
      //crea el div de aviso
      var aviso = document.createElement("div");
      aviso.setAttribute("id", "aviso");
      aviso.style.cssText = "display: flex; justify-content: end;";
      var span = document.createElement("span");
      span.setAttribute("class", "aviso_text");
      span.innerHTML = text;
      span.style.cssText =
        "display: flex; justify-content: center; width: 130px; padding: 2px; margin-bottom: 8px;" +
        " background: #4185ec75; border-radius: 8px; font-size: 12px; font-weight:bold;";
      aviso.appendChild(span);
      //posa el div creat després del div share
      var title_div = document.getElementById("title");
      title_div.insertAdjacentElement("afterend", aviso);
      //elimina el div aviso als 3 segons
      setTimeout(() => {
        aviso.remove();
      }, 3000);
      0;
    },
    whatsappLink() {
      window.open(
        "https://api.whatsapp.com/send?text=Escuchemos%20mi%20lista%20juntos!%20" +
          this.linkRoom
      );
    },
    telegramLink() {
      window.open(
        "https://t.me/share/url?text=Escuchemos%20mi%20lista%20juntos!%20&url=" +
          this.linkRoom
      );
    },
  } /* fin methods */,
  mounted: function () {
    /* carga en forma de script la biblioteca youtube per a que funcione YT  */
    var tag = document.createElement("script");
    tag.src = "https://www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    //crida per a crear object player

    window.addEventListener("load", this.createdPlayer);

    //reb dades del servidor
    this.rebreDadesServer();
  },
}; /* fin export */
</script>

<style scoped>
.container {
  max-width: 1193px;
  min-height: 36.6vh;
}
#column-left,
#column-right {
  border-radius: 12px;
  padding: 30px;
  margin: 30px;
  box-shadow: 0px 10px 22px -1px rgba(0, 0, 0, 0.25);
  margin-top: 10px;
  align-self: flex-start;
  /* max-width: 50%;
  width: 45%;
  height: 200px;*/
}
.jumbotron {
  padding: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
  text-align: center;
}

/* input {
  height: 35px;
  margin-left: 5px;
} */
.add {
  margin-left: 20px;
  height: 35px;
  border: none;
  border-radius: 5px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
#buttons,
#list .col-3 {
  text-align: end;
}
#rep-son-buttons {
  padding-left: 0.5rem;
  text-align: end;
}
#rep-son-buttons .btn,
#buttons .btn {
  padding: 5px;
}
.btn {
  font-size: 1.5rem;
}

div {
  align-self: center;
}
h1,
h2 {
  text-align: center;
}
#title {
  display: flex;
  justify-content: space-between;
}
#column-left h2 {
  display: flex;
}

.list-group-item {
  display: inherit;
  padding: 0.5rem;
}
.active {
  display: inherit;
}
.inactive {
  display: none;
}
.color {
  color: #86b7fe;
}
.btn:hover {
  color: #4185ec;
}
.btn-outline-secondary:hover {
  color: #fff;
}

#progressBar {
  display: flex;
  justify-content: center;
}
#progressBar p {
  margin: 10px;
}

progress[value]::-webkit-progress-bar {
  background-color: #eee;
  border-radius: 2px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}

progress[value]::-webkit-progress-value {
  background-color: #4185ec;
  border-radius: 2px;
}
progress[value] {
  background-color: #4185ec;
  border-radius: 2px;
  height: 5px;
  align-self: center;
}
progress:not([value]) {
  display: none;
}

/* Botones de compartir */
font-awesome-icon {
  opacity: 0;
  font-size: 28px;
  will-change: transform;
  -webkit-transform: scale(0.1);
  transform: scale(0.1);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.btn_wrap {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  width: 130px;
  height: 40px;
  background-color: #e1ebfc;
  border-radius: 80px;
  padding: 0 18px;
  margin-bottom: 8px;
  will-change: transform;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.btn_wrap:hover {
  /* transition-delay: .4s; */
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

span {
  position: absolute;
  z-index: 99;
  width: 130px;
  height: 40px;
  border-radius: 80px;
  /*  font-size: 20px; */
  text-align: center;
  line-height: 40px;
  letter-spacing: 1px;
  color: #ffffff;
  background-color: #1f1e1e;
  padding: 0 18px;
  -webkit-transition: all 1.2s ease;
  transition: all 1.2s ease;
}

.share_btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 150px;
  height: 64px;
  border-radius: 80px;
}

.share_btn font-awesome-icon:nth-of-type(1) {
  -webkit-transition-delay: 1.1s;
  transition-delay: 1.1s;
}

.share_btn font-awesome-icon:nth-of-type(2) {
  -webkit-transition-delay: 0.9s;
  transition-delay: 0.9s;
}

.share_btn font-awesome-icon:nth-of-type(3) {
  -webkit-transition-delay: 0.7s;
  transition-delay: 0.7s;
}

.share_btn font-awesome-icon:nth-of-type(4) {
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

.btn_wrap:hover span {
  -webkit-transition-delay: 0.25s;
  transition-delay: 0.25s;
  -webkit-transform: translateX(-280px);
  transform: translateX(-280px);
}

.btn_wrap:hover font-awesome-icon {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
/* mobils */
@media (max-width: 575.98px) {
  .list-group-item {
    display: block;
    padding: 0.5rem;
  }
  #rep-son-buttons,
  #buttons {
    padding-left: 0.5rem;
    text-align: center;
  }
  .row {
    justify-content: center;
  }
  #column-left,
  #column-right {
    margin: 30px 0px !important;
  }
}
/* tablets*/

@media (max-width: 1024px) {
  .container {
    min-height: 55vh;
  }
}
</style>
